import React, { useState, useEffect } from "react";
import ListView from '../../components/list-view/ListView'; 
import { Container, Badge } from "react-bootstrap";
import { FileText, LockFill } from 'react-bootstrap-icons';
import EmptyListMessage from '../../components/empty-list-message/EmptyListMessage';
import InputModal from '../../components/input-modal/InputModal';
import MessageBox from '../../components/message-box/MessageBox';
import useApiGet from "../../hooks/useApiGet";
import useApiPostJson from "../../hooks/useApiPostJson";
import useApiPatch from "../../hooks/useApiPatch";
import useApiDelete from "../../hooks/useApiDelete";
import useApiFileDownload from "../../hooks/useApiFileDownload";
import useApiFileUpload from "../../hooks/useApiFileUpload";
import RelativeDateWithTooltip from '../../components/relative-date-with-tooltip/RelativeDateWithTooltip';
import './SystemInstructions.scss';

function SystemInstructions() {
  const [instructions, setInstructions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentInstruction, setCurrentInstruction] = useState("");
  const [currentId, setCurrentId] = useState(null);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [targetId, setTargetId] = useState(null); 
  const [pendingDeleteId, setPendingDeleteId] = useState(null);

  const [apiData, fetchApiData] = useApiGet("api/chat-instructions", true);
  const [postResponse, postInstruction] = useApiPostJson("api/chat-instructions");
  const [patchResponse, requestUpdate] = useApiPatch("api/chat-instructions/{id}");
  const [deleteResponse, requestDelete] = useApiDelete("api/chat-instructions/{id}");
  const handleExport = useApiFileDownload("api/chat-instructions/export");
  const [uploadResponse, handleImport, resetUploadResponse] = useApiFileUpload(`api/chat-instructions/import`);

  const handleFileImport = (file) => {
    handleImport(file);
  };

  useEffect(() => {
    if (uploadResponse && uploadResponse.ok) {
      fetchApiData();
      resetUploadResponse();
    }
  }, [uploadResponse, fetchApiData, resetUploadResponse]);

  useEffect(() => {
    if (apiData) {
      const formattedData = apiData.map(item => ({
        id: item.id,
        text: item.instructionText,
        isBuiltIn: item.isBuiltIn,
        lastModified: item.lastModified,
        position: item.position,
        category: item.category
      }));
      setInstructions(formattedData);
    }
  }, [apiData]);

  useEffect(() => {  
    if (postResponse && postResponse.id) {
      const newInstruction = {
        id: postResponse.id,
        text: postResponse.instructionText,
        isBuiltIn: postResponse.isBuiltIn,
        lastModified: postResponse.lastModified,
        position: postResponse.position,
      };
  
      setInstructions((prevInstructions) => [...prevInstructions, newInstruction]);
    }
  }, [postResponse]);
  
  useEffect(() => {
    if (patchResponse && patchResponse.status === 200) {
      setInstructions((prevInstructions) =>
        prevInstructions.map(instr =>
          instr.id === currentId
            ? {
                ...instr,
                lastModified: Date.now(),
                text: currentInstruction
              }
            : instr
        )
      );
    }
  }, [patchResponse, currentId, currentInstruction]);

  useEffect(() => {
    if (deleteResponse && deleteResponse.status === 200 && pendingDeleteId !== null) {
      setInstructions((prevInstructions) =>
        prevInstructions.filter(instr => instr.id !== pendingDeleteId)
      );
      setShowMessageBox(false);
      setPendingDeleteId(null);
    }
  }, [deleteResponse, pendingDeleteId]);

  const handleAddInstruction = () => {
    setCurrentId(null);
    setCurrentInstruction("");
    setShowModal(true);
  };


  const handleEditInstruction = (id) => {
    const instruction = instructions.find(instr => instr.id === id);
    if (instruction) {
      setCurrentId(id);
      setCurrentInstruction(instruction.text);
      setShowModal(true);
    }
  };

  const confirmDelete = () => {
    setPendingDeleteId(targetId);
    requestDelete({ params: { id: targetId } });
    setShowMessageBox(false);
  };

  const handleDeleteInstruction = (id) => {
    setTargetId(id);
    setShowMessageBox(true);
  };

  const handleSaveInstruction = (instructionText) => {
    const instructionRequest = {
      chatInstruction: instructionText,
    };
    setCurrentInstruction(instructionText);
    if (currentId !== null) {
      requestUpdate({
        body: instructionRequest,
        params: { id: currentId }
      });
    } else {
      postInstruction({
        body: instructionRequest
      });
    }

    setShowModal(false);
  };
 
  const renderMetadata = (item) => {
    return (
      <RelativeDateWithTooltip 
        date={item.lastModified} 
        label="Modified: " 
      />
    );
  };

  const renderTags = (item) => {
    if (item.isBuiltIn) {
      return (
        <Badge pill bg="secondary">
          <LockFill className="me-1" />
          Built-In
        </Badge>
      );
    }
    return null;
  };

  const sortedInstructions = [...instructions].sort((a, b) => {
    if (a.isBuiltIn && !b.isBuiltIn) return -1;
    if (!a.isBuiltIn && b.isBuiltIn) return 1;
    return a.position - b.position;
  });

  return (
    <Container>
      <div>
        {instructions.length === 0 ? (
          <EmptyListMessage
            onCreate={handleAddInstruction}
            title="System Instructions"
            icon={FileText}
            message="This is the system instructions page. There are no instructions yet. Use the button below to create your first instruction and standardize your development processes."
            buttonText="START"
            importEnabled={true}
            onImport={handleFileImport}
            />) : (
          <>
            <div className="common-header">
              <div className="header-inner">
                <h1>System Instructions</h1>
              </div>
            </div>
            <ListView
              items={sortedInstructions}
              onEdit={handleEditInstruction}
              onDelete={handleDeleteInstruction}
              renderIcon={()=> {return <FileText />}}
              renderMetadata={renderMetadata}
              renderTags={renderTags}
              showEdit={(item) => !item.isBuiltIn}
              showDelete={(item) => !item.isBuiltIn}
              importExportEnabled={true}
              onExport={() => handleExport({}, "instructions_export.json")}
              onImport={handleFileImport}
              onCreate={handleAddInstruction}
              showButtonRow={true}
            />
          </>
        )}

         <InputModal
          show={showModal}
          onHide={() => setShowModal(false)}
          onSubmit={handleSaveInstruction}
          title={currentInstruction ? "Edit Instruction" : "Add Instruction"}
          placeholder="Enter instruction text..."
          rowsNumber={10}
          typeOfInput={"textarea"}
          initialValue={currentInstruction}
          validation={(value) => value.trim() !== ""}
          validationMessage="Instruction text is required"
        />

        <MessageBox
          show={showMessageBox}
          onHide={() => setShowMessageBox(false)}
          onConfirm={confirmDelete}
          title="Delete instruction"
          message="Are you sure you want to delete this instruction?"
          confirmText="Delete"
          cancelText="Cancel"
          isDelete={true}
        />
      </div>
    </Container>
  );
}

export default SystemInstructions;
