import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Tabs, Tab } from 'react-bootstrap';
import { FileText, Terminal } from 'react-bootstrap-icons';
import InputModal from '../../components/input-modal/InputModal';
import useApiGet from '../../hooks/useApiGet';
import useApiPostJson from '../../hooks/useApiPostJson';
import useApiPatch from '../../hooks/useApiPatch';
import useApiDelete from '../../hooks/useApiDelete';
import useApiFileDownload from "../../hooks/useApiFileDownload";
import useApiFileUpload from "../../hooks/useApiFileUpload";
import ListView from '../../components/list-view/ListView';
import RelativeDateWithTooltip from '../../components/relative-date-with-tooltip/RelativeDateWithTooltip';
import MessageBox from '../../components/message-box/MessageBox';
import './CodeRepositories.scss';

function CodeRepositoryDetails() {
  const { id } = useParams();
  const [repository, setRepository] = useState(null);
  const [instructions, setInstructions] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [currentInstruction, setCurrentInstruction] = useState("");
  const [currentCategory, setCurrentCategory] = useState("");
  const [currentId, setCurrentId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [targetId, setTargetId] = useState(null); 
  const [pendingDeleteId, setPendingDeleteId] = useState(null);

  const [apiData, fetchApiData] = useApiGet(`api/code-repositories/${id}`, true);
  const [postResponse, postInstruction] = useApiPostJson('api/chat-instructions');
  const [patchResponse, requestUpdate] = useApiPatch('api/chat-instructions/{id}');
  const [deleteResponse, requestDelete] = useApiDelete('api/chat-instructions/{id}');
  const handleExport = useApiFileDownload(`api/chat-instructions/export/${id}`);
  const [uploadResponse, handleImport, resetUploadResponse] = useApiFileUpload(`api/chat-instructions/import/${id}`);

  const handleFileImport = (file) => {
    handleImport(file);
  };

  useEffect(() => {
    if (uploadResponse && uploadResponse.ok) {
      fetchApiData();
      resetUploadResponse();
    }
  }, [uploadResponse, fetchApiData, resetUploadResponse]);

  useEffect(() => {
    if (apiData) {
      setRepository({
        id: apiData.id,
        remoteUrl: apiData.remoteUrl,
        created: apiData.created,
        lastModified: apiData.lastModified,
        instructions: apiData.instructions || [],
        sessions: apiData.sessions || [],
        type: apiData.type,
        name: apiData.name || "Repository",
        jiraProjectKeys: apiData.jiraProjectKeys
      });

      const formattedInstructions = apiData.instructions.map(item => ({
        id: item.id,
        text: item.instructionText,
        category: item.category,
        isBuiltIn: item.isBuiltIn,
        lastModified: item.lastModified,
        position: item.position
      }));

      setInstructions(formattedInstructions);
      const formattedSessions = apiData.sessions.map(item => ({
        id: item.clientSessionId,
        title: `User: ${item.gitUsername}`,
        text: `Session ID: ${item.clientSessionId}`,
        sessionState: item.sessionState,
        created: item.created,
      }));

      setSessions(formattedSessions);
    }
  }, [apiData]);

  useEffect(() => {  
    if (postResponse && postResponse.id) {
      const newInstruction = {
        id: postResponse.id,
        text: postResponse.instructionText,
        category: postResponse.category,
        isBuiltIn: postResponse.isBuiltIn,
        lastModified: postResponse.lastModified,
        position: postResponse.position,
      };
      setInstructions((prevInstructions) => [...prevInstructions, newInstruction]);
    }
  }, [postResponse]);
  
  useEffect(() => {
    if (patchResponse && patchResponse.status === 200) {
      setInstructions((prevInstructions) =>
        prevInstructions.map(instr =>
          instr.id === currentId
            ? {
                ...instr,
                lastModified: Date.now(),
                category: currentCategory,
                text: currentInstruction
              }
            : instr
        )
      );
    }
  }, [patchResponse, currentId, currentCategory, currentInstruction]);

  useEffect(() => {
    if (deleteResponse && deleteResponse.status === 200 && pendingDeleteId !== null) {
      setInstructions((prevInstructions) =>
        prevInstructions.filter(instr => instr.id !== pendingDeleteId)
      );
      setShowMessageBox(false);
      setPendingDeleteId(null);
    }
  }, [deleteResponse, pendingDeleteId]);

  const handleAddInstruction = () => {
    setCurrentId(null);
    setCurrentInstruction("");
    setCurrentCategory("");
    setShowModal(true);
  };

  const handleEditInstruction = (id) => {
    const instruction = instructions.find(instr => instr.id === id);
    if (instruction) {
      setCurrentId(id);
      setCurrentInstruction(instruction.text);
      setCurrentCategory(instruction.category);
      setShowModal(true);
    }
  };

  const confirmDelete = () => {
    setPendingDeleteId(targetId);
    requestDelete({ params: { id: targetId } });
    setShowMessageBox(false);
  };

  const handleDeleteInstruction = (id) => {
    setTargetId(id);
    setShowMessageBox(true);
  };

  const handleSaveInstruction = (instructionText, category) => {
    const instructionRequest = {
      chatInstruction: instructionText,
      category: category || "",
      repositoryId: apiData.id
    };
    setCurrentInstruction(instructionText);
    setCurrentCategory(category);
    if (currentId !== null) {
      requestUpdate({
        body: instructionRequest,
        params: { id: currentId }
      });
    } else {
      postInstruction({
        body: instructionRequest
      });
    }
    setShowModal(false);
  };

  if (!repository) {
    return <Container>Loading repository details...</Container>;
  }

  return (
    <Container className="repo-detail-container">
      <div className="repository-header">
        <div className="repository-header-inner">
          <div className="metadata-group-left">
            <h2>{repository.name}</h2>
            <p className="repository-type">
              <strong>TYPE:&nbsp;</strong>
              <span className={`repository-type-value ${repository.type.toLowerCase()}`}>
                {repository.type}
              </span>
            </p>
            <p className="repository-url">
              <strong>URL:</strong>&nbsp;
              <a href={repository.remoteUrl} target="_blank" rel="noopener noreferrer">
                {repository.remoteUrl}
              </a>
            </p>
            {repository.jiraProjectKeys && repository.jiraProjectKeys.trim() && (
            <p className="jira-projects">
              <strong>JIRA Projects:</strong>&nbsp;
              {repository.jiraProjectKeys.split(',').map((key, index) => (
                <span key={index}>
                  <a
                    href={`https://macrixsoftware.atlassian.net/projects/${key.trim()}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {key.trim()}
                  </a>
                  {index < repository.jiraProjectKeys.split(',').length - 1 && ', '}
                </span>
              ))}
            </p>
          )}
          </div>
          <div className="metadata-group-right">
            <span className="created-date">
              <RelativeDateWithTooltip date={repository.created} label="Created: " />
            </span>
            <span className="modified-date">
              <RelativeDateWithTooltip date={repository.lastModified} label="Modified: " />
            </span>
          </div>
        </div>
      </div>
      
      <Tabs defaultActiveKey="instructions" id="repository-details-tabs" className="details-tab mb-3">
        <Tab eventKey="instructions" title="Instructions">
          <ListView
            className="instructions-list"
            items={instructions}
            onEdit={handleEditInstruction}
            onDelete={handleDeleteInstruction}
            renderIcon={()=> {return <FileText />}}
            renderMetadata={(item) => (
              <RelativeDateWithTooltip date={item.lastModified} label=" Modified: " />
            )}
            showEdit={() => true}
            showDelete={() => true}
            emptyListText="Instructions list empty"
            importExportEnabled={true}
            onExport={() => handleExport(id, `instructions_export_${id}.json`)}
            onImport={handleFileImport} 
            onCreate={handleAddInstruction}
            showButtonRow={true}
          />
        </Tab>

        <Tab eventKey="sessions" title="Sessions" className="details-tab mb-3">
          <ListView
            className="sessions-list"
            items={sessions}
            renderIcon={()=> {return <Terminal />}}
            renderMetadata={(item) => (
              <RelativeDateWithTooltip date={item.created} label=" Created: " />
            )}
            showEdit={() => false}
            showDelete={() => false}
            emptyListText="No sessions"
          />
        </Tab>
      </Tabs>

      <InputModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onSubmit={handleSaveInstruction}
        title="Add Instruction"
        label="Instruction Text"
        placeholder="Enter instruction text..."
        rowsNumber={10}
        typeOfInput={"textarea"}
        initialValue={currentInstruction}
        validation={(value) => value.trim() !== ""}
        validationMessage="Instruction text is required"
      />

      <MessageBox
        show={showMessageBox}
        onHide={() => setShowMessageBox(false)}
        onConfirm={confirmDelete}
        title="Delete instruction"
        message="Are you sure you want to delete this instruction?"
        confirmText="Delete"
        cancelText="Cancel"
        isDelete={true}
      />
    </Container>
  );
}

export default CodeRepositoryDetails;
