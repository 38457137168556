import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ListView from '../../components/list-view/ListView'; 
import useApiGet from '../../hooks/useApiGet';
import useApiPostJson from '../../hooks/useApiPostJson';
import useApiPatch from '../../hooks/useApiPatch';
import useApiDelete from '../../hooks/useApiDelete';
import RelativeDateWithTooltip from '../../components/relative-date-with-tooltip/RelativeDateWithTooltip';
import MessageBox from '../../components/message-box/MessageBox';
import { Gitlab } from 'react-bootstrap-icons';
import InputModal from '../../components/input-modal/InputModal'; 
import getLastPartOfUrl from '../../js-helpers/GetLastPartOfUrl';
import EmptyListMessage from '../../components/empty-list-message/EmptyListMessage';
import './CodeRepositories.scss';

function CodeRepositories() {
  const [repositories, setRepositories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const [currentName, setCurrentName] = useState("");
  const [currentJiraProjectKeys, setCurrentJiraProjectKeys] = useState("");
  const [currentId, setCurrentId] = useState(null);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [targetId, setTargetId] = useState(null); 
  const [pendingDeleteId, setPendingDeleteId] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const navigate = useNavigate();

  const [apiData] = useApiGet('api/code-repositories', true);
  const [postResponse, postRepository] = useApiPostJson('api/code-repositories');
  const [patchResponse, requestUpdate] = useApiPatch('api/code-repositories/{id}');
  const [deleteResponse, requestDelete] = useApiDelete('api/code-repositories/{id}');

  useEffect(() => {
    if (apiData) {
      const formattedData = apiData.map(repo => {
        const lastPartOfUrl = getLastPartOfUrl(repo.remoteUrl);
        
        return {
          id: repo.id,
          title: repo.name || lastPartOfUrl,
          remoteUrl: repo.remoteUrl,
          text: (
            <>
              Open Sessions: {repo.numberOfSessions}
              {repo.jiraProjectKeys && (
                <>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  JIRA Projects: {repo.jiraProjectKeys}
                </>
              )}
            </>
          ),
          sessions: repo.sessions,
          instructions: repo.instructions,
          createdAt: repo.created,
          numberOfSessions: repo.numberOfSessions,
          name: repo.name,
          jiraProjectKeys: repo.jiraProjectKeys
        };
      });
      
      setRepositories(formattedData);
    }
  }, [apiData]);  

  useEffect(() => {
    if (postResponse && postResponse.id) {
      const lastPartOfUrl = getLastPartOfUrl(postResponse.remoteUrl);
      const newRepository = {
        id: postResponse.id,
        remoteUrl: postResponse.remoteUrl,
        createdAt: postResponse.created,
        title: postResponse.name || lastPartOfUrl,
        text: `Open Sessions: 0`,
        numberOfSessions: 0,
        sessions: [],
        instructions: [],
        name: postResponse.name,
        jiraProjectKeys: postResponse.jiraProjectKeys
      };
      setRepositories(prevRepositories => [...prevRepositories, newRepository]);
    }
  }, [postResponse]);

  useEffect(() => {
    if (patchResponse && patchResponse.status === 200) {
      setRepositories(prevRepositories =>
        prevRepositories.map(repo => {
          const lastPartOfUrl = getLastPartOfUrl(repo.remoteUrl);
  
          return repo.id === currentId
            ? { 
                ...repo, 
                remoteUrl: currentUrl, 
                name: currentName, 
                title: currentName || lastPartOfUrl,
                jiraProjectKeys: currentJiraProjectKeys,
                text: (
                  <>
                    Open Sessions: {repo.numberOfSessions}
                    {currentJiraProjectKeys && (
                      <>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        JIRA Projects: {currentJiraProjectKeys}
                      </>
                    )}
                  </>
                )
              }
            : repo;
        })
      );
    }
  }, [patchResponse, currentId, currentUrl, currentName, currentJiraProjectKeys]);  

  useEffect(() => {
    if (deleteResponse && deleteResponse.status === 200 && pendingDeleteId !== null) {
      setRepositories(prevRepositories =>
        prevRepositories.filter(repo => repo.id !== pendingDeleteId)
      );
      setShowMessageBox(false);
      setPendingDeleteId(null);
    }
  }, [deleteResponse, pendingDeleteId]);

  const handleOpenDetails = (id, name) => {
    navigate(`/repositories/${id}`, { state: { crumbName: name } });
  };

  const handleAddRepository = () => {
    setCurrentId(null);
    setCurrentUrl("");
    setCurrentName("");
    setCurrentJiraProjectKeys("");
    setShowModal(true);
  };

  const handleEditRepository = (id) => {
    const repository = repositories.find(x => x.id === id);
    if (repository) {
      setCurrentId(id);
      setCurrentUrl(repository.remoteUrl);
      setCurrentName(repository.name);
      setCurrentJiraProjectKeys(repository.jiraProjectKeys);
      setIsEditMode(true);
      setShowModal(true);
    }
  };

  const confirmDelete = () => {
    setPendingDeleteId(targetId);
    requestDelete({ params: { id: targetId } });
    setShowMessageBox(false);
  };

  const handleDeleteRepository = (id) => {
    setTargetId(id);
    setShowMessageBox(true);
  };

  const handleSaveRepository = (remoteUrl, opt) => {
    const repositoryRequest = {
      url: remoteUrl,
      name: opt && opt[0] ? opt[0] : "",
      jiraProjectKeys: opt && opt[1] ? opt[1] : "",
    };
    setCurrentUrl(remoteUrl);
    setCurrentName(opt && opt[0] ? opt[0] : "");
    setCurrentJiraProjectKeys(opt && opt[1] ? opt[1] : "",);
    if (currentId !== null) {
      requestUpdate({
        body: repositoryRequest,
        params: { id: currentId }
      });
    } else {
      postRepository({
        body: repositoryRequest
      });
    }
    setIsEditMode(false);
    setShowModal(false);
  };

  const isValidUrl = (value) => {
    try {
      new URL(value);
      return true;
    } catch (e) {
      return false;
    }
  };

  const renderMetadata = (item) => {
    return (
      <RelativeDateWithTooltip 
        date={item.createdAt} 
        label="Created: " 
      />
    );
  };

  return (
    <Container className="repos-container">
        {repositories.length === 0 ? (
          <EmptyListMessage
            onCreate={handleAddRepository}
            title="CodeRepositories"
            icon={Gitlab}
            message="This is the code repositories page. There are no repositories yet. Use the button below to create your first entry."
            buttonText="START"
          />
        ) :
      (
      <>
        <div className="repository-header">
          <div className="header-inner d-flex justify-content-between align-items-center">
            <h1>Repositories</h1>
            <div className="create-block">
              <Button className="create-button" onClick={handleAddRepository}>
                Create
              </Button>
            </div>           
          </div>
        </div>      
        <ListView
          items={repositories}
          onItemClick={handleOpenDetails}
          onEdit={handleEditRepository}
          onDelete={handleDeleteRepository}
          showEdit={() => true}
          showDelete={() => true}
          renderIcon={() => {return <Gitlab />;}}
          renderMetadata={renderMetadata}
        />
      </>
      )}

      <InputModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onSubmit={handleSaveRepository}
        title={isEditMode ? "Edit Repository" : "Create Repository"}
        label="Repository URL"
        placeholder="Enter repository URL"
        initialValue={currentUrl}
        validation={isValidUrl}
        validationMessage="Enter a valid URL"
        optionalFields={[
          {
            label: "Repository Name",
            placeholder: "Enter repository name (optional)",
            initialValue: currentName,
          },
          {
            label: "JIRA project keys",
            placeholder: "Enter JIRA project keys (optional)",
            initialValue: currentJiraProjectKeys,
            mutedText: "Separate values with commas",
            validation: (value) => value.includes(',') || value.trim().split(',').length === 1,
            validationMessage: "Please separate multiple JIRA project keys with commas.",
          }
        ]}
      />

      <MessageBox
        show={showMessageBox}
        onHide={() => setShowMessageBox(false)}
        onConfirm={confirmDelete}
        title="Delete Repository"
        message="Are you sure you want to delete this repository?"
        confirmText="Delete"
        cancelText="Cancel"
        isDelete={true}
      />
    </Container>
  );
}

export default CodeRepositories;
